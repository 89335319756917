<script>
import LoadingVue from '@/components/ui/Loading.vue'

import { getLabPatent } from '@/api/platform/community.js'

import LanguageConverter from '@/util/LanguageConverter'

import V2FileApi from '@/api/v2FileApi'

export default {
  data() {
    return {
      pageId: this.$route.params.id,
      listUrl: this.$route.path.slice(0, this.$route.path.lastIndexOf('/')),
      isLoading: false,
      loadedData: null,
      fileApi: null,
      attachedFiles: []
    }
  },
  components: { LoadingVue },
  methods: {
    LanguageConverter,
    numberFormat(num) {
      return new Intl.NumberFormat().format(num)
    },
    async getData() {
      this.isLoading = true
      const data = await getLabPatent(this.pageId)

      if (data.patentRegisterDate !== null) {
        data.patentRegisterDate = this.$getDate(data.patentRegisterDate)
      }

      this.isLoading = false
      return data
    },
    goList() {
      this.$router.push(this.listUrl)
    },
    async fileDownload(fileUuidName) {
      if (!confirm('파일을 다운로드 하시겠습니까?')) return

      const result = await this.fileApi.downloadFile(fileUuidName, 'files')
      if (result.code) alert(result.message)
    }
  },
  async mounted() {
    this.fileApi = new V2FileApi({ boardName: 'patent', boardId: this.pageId })

    this.loadedData = await this.getData()
    this.attachedFiles = await this.fileApi.getFileList()
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="wf__section" v-if="loadedData">
    <div class="wf__title-wrapper">
      <h2 class="wf__title">지재권</h2>
      <div class="divider"></div>

      <div class="wf__board-title-wrapper">
        <h3 class="wf__board-title">{{ loadedData.patentResultTitle }}</h3>

        <router-link class="wf__author" :to="`/lab/${loadedData.labNo}`">{{ loadedData.labName }} 바로가기</router-link>

        <ul class="wf__board-desc">
          <li>{{ $getDate(loadedData.createdAt) }}</li>
          <li>|</li>
          <li>조회 {{ numberFormat(loadedData.patentViewCount) }}</li>
        </ul>
      </div>
    </div>

    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>{{ LanguageConverter({ en: 'Registrant', kr: '출원등록인' }) }}</th>
            <td>{{ loadedData.patentRegistrant }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Regist Number', kr: '출원등록번호' }) }}</th>
            <td>{{ loadedData.patentRegistNumber }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Regist Date', kr: '출원등록년도' }) }}</th>
            <td>{{ loadedData.patentYear ? new Date(loadedData.patentYear).getFullYear() : '' }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Regist Type', kr: '지재권구분' }) }}</th>
            <td>{{ loadedData.patentRegistType }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Regist Country', kr: '출원등록국' }) }}</th>
            <td>{{ loadedData.patentRegistCountry === '대한민국' ? '국내' : '국외' }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Technology RoadMap', kr: '국가기술지도명' }) }}</th>
            <td>{{ loadedData.patentTechnologyRoadMap }}</td>
          </tr>

          <tr>
            <th>{{ LanguageConverter({ en: 'Ministry', kr: '부처명' }) }}</th>
            <td>{{ loadedData.patentMinistryName }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>{{ LanguageConverter({ en: 'Apply Field', kr: '적용 가능 분야' }) }}</th>
            <td>{{ loadedData.patentApplyField }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Man Power', kr: '개발 인력' }) }}</th>
            <td>{{ loadedData.patentManPower }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Grow Level', kr: '기술 성숙도 단계' }) }}</th>
            <td>{{ loadedData.patentGrowLevel?.substring(0, 1) || '' }}단계</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Summary', kr: '요약정보' }) }}</th>
            <td>{{ loadedData.patentSummary }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Attachments', kr: '첨부파일' }) }}</th>
            <td>
              <div v-if="attachedFiles.length !== 0">
                <label class="wf__attached-file-item" v-for="(file, i) in attachedFiles" :key="i">
                  <input type="text" :value="file.fileName" disabled />

                  <div class="wf__attached-file-item--buttons">
                    <button
                      type="button"
                      class="e-btn wf__btn btn-success"
                      :style="{ padding: '0px !important' }"
                      @click="fileDownload(attachedFiles[i].fileUuidName)"
                    >
                      <span class="material-icons-outlined"> file_download </span>
                    </button>
                  </div>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="divider"></div>

    <div>
      <span class="wf__notice-list-btn" @click="goList">List</span>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__section {
  margin: 4rem 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  font-size: var(--wf-text-16);
}

.wf__title-wrapper {
  text-align: center;
}
.wf__board-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  font-size: 16px;
  font-weight: 300;
}
.wf__title {
  font-size: var(--wf-text-24);
  font-weight: bold;
  text-align: left;
}
.wf__board-title {
  word-break: normal;
  font-size: 38px;
  font-weight: 700;
  margin-top: 46px;
  max-width: 28em;
}
.wf__board-desc {
  display: flex;
  justify-content: center;
  gap: 0.5em;
}
.wf__author {
  color: var(--wf-primary-color);
  font-weight: 400;
}
.divider {
  height: 2px;
  background-color: var(--wf-gray-color);

  margin: 1rem 0;
}

.adminTable,
.adminTable table {
  min-width: 0;
  width: 100%;
  word-break: normal;
}
.adminTable th {
  text-align: center;
  font-size: 16px;
}
.adminTable td {
  padding: 1em 2em;
  font-size: 16px;
}

.wf__notice-list-btn {
  font-size: 1em;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #fff;
  background-color: var(--wf-primary-color);
  float: right;
  cursor: pointer;
  border-radius: 0.25em;
  padding: 0.35em 1em;
}
.wf__notice-list-btn:hover {
  opacity: 0.75;
}

.wf__attached-file-item {
  display: flex;
  gap: 1em;
}
.wf__attached-file-item > input:first-child {
  padding: 0.25em 0.5em;
  max-width: max-content;
}
.wf__attached-file-item--buttons {
  display: flex;
  gap: 0.5em;
}
</style>
